<template>
  <div class="gardenName">
    <el-tabs v-model="activeTabName" @tab-click="changeTab">
      <el-tab-pane label="全部园所" name="first" @click="changeTab(0)"></el-tab-pane>
      <el-tab-pane label="足特园所" name="second" @click="changeTab(3)"></el-tab-pane>
      <el-tab-pane label="示范园所" name="third" @click="changeTab(4)"></el-tab-pane>
      <el-tab-pane label="公益园所" name="fourth" @click="changeTab(5)"></el-tab-pane>
      <div>
        <!--搜索-->
        <div class="searchBox">
            <el-form :inline="true" ref="schoolForm" :model="listParam" class="demo-form-inline">
            <el-form-item label="请选择省" v-if="1 == rank">
              <el-select v-model="listParam.province" multiple clearable collapse-tags     style="margin-left: 10px;" placeholder="请选择省" >
                <el-option
                    v-for="(p, i) in areasProvince" :key="`province-${i}`"
                    :value="p.value"
                    :label="p.label">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="请选择市" multiple  v-if="2 == rank">
                <el-select v-model="listParam.city" multiple clearable collapse-tags   placeholder="请选择市">
                  <el-option
                      v-for="(p, i) in cities" :key="`province-${i}`"
                      :value="p.value"
                      :label="p.label">
                  </el-option>
                </el-select>
              </el-form-item>
          <el-form-item label="请选择年度" v-if="listParam.typeTab== 1 ||listParam.typeTab==2">
            <el-select v-model="listParam.year" clearable placeholder="请选择年度">
              <el-option
                  v-for="(p, i) in dataTypes" :key="`dataTypes-${i}`"
                  :value="p.value"
                  :label="p.label">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="exportBtn">
            <el-button class="export" type="primary" @click="search">查询</el-button>
            <el-button  class="export"  @click="reset">重置</el-button>
            <el-button  class="export"  @click="getExpor">导出</el-button>
          </el-form-item>
          </el-form>
          <div class="provinceAdds" v-if="1 == rank">
            <ul>
              <li v-for="(p, i) in listParam.province" :key="`cp-${p}`">
                {{p}}
                <em @click="removeProvince(i)"></em>
              </li>
            </ul>
          </div>
          <div class="provinceAdds" v-if="2 == rank">
            <ul>
              <li v-for="(p, i) in listParam.city" :key="`cp-${p}`">
                {{p}}
                <em @click="removeCity(i)"></em>
              </li>
            </ul>
          </div>
        </div>
        <!--督导信息列表-->
        <div class="box-shadow">
          <el-table :data="gardenList" stripe style="width:100%;">
            <el-table-column
                :resizable="false"
                width="80"
                show-overflow-tooltip
                label="序号">
              <template slot-scope="scope">
                  {{(pageParams.page - 1 ) * pageParams.size + scope.$index + 1}}
              </template>
            </el-table-column>
            <el-table-column
                v-for="h in gardenListHeader(rank)" :key="`tab-col-${rank}-${listParam.typeTab}-${h.prop}`"
                :resizable="false"
                show-overflow-tooltip
                :prop="h.prop"
                :label="h.label">
              <template slot-scope="scope">
                <div v-if="h.prop=== 'schoolCount'">{{formatNumber(scope.row.schoolCount)}}</div>
                <div v-else-if="h.prop=== 'studentCount'">{{formatNumber(scope.row.studentCount)}}</div>
                <div v-else-if="h.prop=== 'schoolZtCount'">{{formatNumber(scope.row.schoolZtCount)}}</div>
                <div v-else-if="h.prop=== 'schoolSfCount'">{{formatNumber(scope.row.schoolSfCount)}}</div>
                <div v-else-if="h.prop=== 'schoolGyCount'">{{formatNumber(scope.row.schoolGyCount)}}</div>
                <div v-else-if="h.prop=== 'supportStudentCount'">{{formatNumber(scope.row.supportStudentCount)}}</div>
                <div v-else-if="h.prop=== 'scores'">{{formatNumber(scope.row.scores)}}</div>
                <div v-else>{{scope.row[h.prop] }}</div>
              </template>
            </el-table-column>
            <el-table-column
                :resizable="false"
                show-overflow-tooltip
                prop="address"
                label="操作">
              <template slot-scope="scope">
                <el-button
                    @click="showKindergarten(scope.row)"
                    size="mini">查看
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </el-tabs>
    <!--全部统计-->
    <div class="boxPaddingNone">
      <!--全部-->
      <el-table class="box-shadow"  v-if="listParam.typeTab==0" :data="gardenListAll" stripe style="width:100%;margin-top: 20px;margin-bottom: 20px" :show-header="false">
        <el-table-column
            show-overflow-tooltip
            prop=""
            label="全部">
          <template>
            <span class="emAll">统计</span>
          </template>
        </el-table-column>
        <el-table-column
            show-overflow-tooltip
            prop=""
            width="150"
            label="园所数">
          <template slot-scope="scope">
            <span style="color: #333;font-weight: bold">共计{{formatNumber(scope.row.schoolCount)}}所幼儿园</span>
          </template>
        </el-table-column>
        <el-table-column
            show-overflow-tooltip
            prop=""
            width="50"
            label="总注册园所">
        </el-table-column>
        <el-table-column
            show-overflow-tooltip
            prop=""
            label="总幼儿数">
          <template slot-scope="scope">
              <div>{{formatNumber(scope.row.studentCount)}}</div>
          </template>
        </el-table-column>
        <el-table-column
            show-overflow-tooltip
            prop=""
            label="足特园所数">
          <template slot-scope="scope">
            <div>{{formatNumber(scope.row.schoolZtCount)}}</div>
          </template>
        </el-table-column>
        <el-table-column
            show-overflow-tooltip
            prop=""
            label="示范园所数">
          <template slot-scope="scope">
            <div>{{formatNumber(scope.row.schoolSfCount)}}</div>
          </template>
        </el-table-column>
        <el-table-column
            show-overflow-tooltip
            prop=""
            label="公益园所数">
          <template slot-scope="scope">
            <div>{{formatNumber(scope.row.schoolGyCount)}}</div>
          </template>
        </el-table-column>
        <el-table-column
            show-overflow-tooltip
            prop="scores"
            label="积分">
          <template slot-scope="scope">
            <div>{{formatNumber(scope.row.scores)}}</div>
          </template>
        </el-table-column>
        <el-table-column
            :resizable="false"
            show-overflow-tooltip
            prop="address"
            label="操作">
          <template>
            <el-button size="mini" @click="showKindergarten({status:1, province:$store.state.userInfo.province,provinceInfo:$store.state.userInfo.provinceId})" >查看
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!--足特-->
      <el-table  class="box-shadow"  v-if="listParam.typeTab== 1" :data="gardenListAll" stripe style="width:100%;margin-top: 20px;margin-bottom: 20px" :show-header="false">
        <el-table-column
            show-overflow-tooltip
            prop="全部"
            label="全部">
          <template>
            <span class="emAll">统计</span>
          </template>
        </el-table-column>
        <el-table-column
            show-overflow-tooltip
            prop=""
            width="150"
            label="园所数">
          <template slot-scope="scope">
            <span style="font-weight: bold;color:#333;">共计{{formatNumber(scope.row.schoolCount)}}所幼儿园</span>
          </template>
        </el-table-column>
        <el-table-column
            show-overflow-tooltip
            prop=""
            width="50"
            label="总注册园所">
        </el-table-column>
        <el-table-column
            show-overflow-tooltip
            prop=""
            label="足特园所数">
          <template slot-scope="scope">
            <div>{{formatNumber(scope.row.schoolZtCount)}}</div>
          </template>
        </el-table-column>
        <el-table-column
            show-overflow-tooltip
            prop=""
            label="年底">
        </el-table-column>
        <el-table-column
            show-overflow-tooltip
            prop=""
            label="在园幼儿数">
          <template slot-scope="scope">
            <div>{{formatNumber(scope.row.studentCount)}}</div>
          </template>
        </el-table-column>
        <el-table-column
            show-overflow-tooltip
            prop=""
            label="积分">
          <template slot-scope="scope">
            <div>{{formatNumber(scope.row.scores)}}</div>
          </template>
        </el-table-column>
        <el-table-column
            :resizable="false"
            show-overflow-tooltip
            prop="address"
            label="操作">
          <template>
            <el-button size="mini" @click="showKindergarten({status:1, province:$store.state.userInfo.province,provinceInfo:$store.state.userInfo.provinceId})" >查看
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!--示范-->
      <el-table  class="box-shadow"  v-if="listParam.typeTab== 2" :data="gardenListAll" stripe style="width:100%;margin-top: 20px;margin-bottom: 20px" :show-header="false">
        <el-table-column
            show-overflow-tooltip
            prop="全部"
            label="全部">
          <template>
            <span class="emAll">统计</span>
          </template>
        </el-table-column>
        <el-table-column
            show-overflow-tooltip
            prop=""
            width="150"
            label="园所数">
          <template slot-scope="scope">
            <span style="font-weight: bold;color: #333">共计{{formatNumber(scope.row.schoolCount)}}所幼儿园</span>
          </template>
        </el-table-column>
        <el-table-column
            show-overflow-tooltip
            prop=""
            width="50"
            label="总注册园所">
        </el-table-column>
        <el-table-column
            show-overflow-tooltip
            prop=""
            label="示范园所数">
          <template slot-scope="scope">
            <div>{{formatNumber(scope.row.schoolSfCount)}}</div>
          </template>
        </el-table-column>
        <el-table-column
            show-overflow-tooltip
            prop=""
            label="年底">
        </el-table-column>
        <el-table-column
            show-overflow-tooltip
            prop=""
            label="在园幼儿数">
          <template slot-scope="scope">
            <div>{{formatNumber(scope.row.studentCount)}}</div>
          </template>
        </el-table-column>
        <el-table-column
            show-overflow-tooltip
            prop=""
            label="资助幼儿数">
          <template slot-scope="scope">
            <div>{{formatNumber(scope.row.supportStudentCount)}}</div>
          </template>
        </el-table-column>
        <el-table-column
            show-overflow-tooltip
            prop=""
            label="积分">
          <template slot-scope="scope">
            <div>{{formatNumber(scope.row.scores)}}</div>
          </template>
        </el-table-column>
        <el-table-column
            :resizable="false"
            show-overflow-tooltip
            prop="address"
            label="操作">
          <template>
            <el-button size="mini" @click="showKindergarten({status:1, province:$store.state.userInfo.province,provinceInfo:$store.state.userInfo.provinceId})" >查看
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!--公益-->
      <el-table  class="box-shadow"  v-if="listParam.typeTab== 3" :data="gardenListAll" stripe style="width:100%;margin-top: 20px;margin-bottom: 20px" :show-header="false">
      <el-table-column
          show-overflow-tooltip
          prop="全部"
          label="全部">
        <template>
          <span class="emAll">统计</span>
        </template>
      </el-table-column>
      <el-table-column
            show-overflow-tooltip
            prop=""
            width="150"
            label="园所数">
          <template slot-scope="scope">
            <span style="color:#333;font-weight: bold">共计{{formatNumber(scope.row.schoolCount)}}所幼儿园</span>
          </template>
        </el-table-column>
      <el-table-column
          show-overflow-tooltip
          prop=""
          width="50"
          label="总注册园所">
      </el-table-column>
      <el-table-column
          show-overflow-tooltip
          prop=""
          label="公益园所数">
        <template slot-scope="scope">
          <div>{{formatNumber(scope.row.schoolGyCount)}}</div>
        </template>
      </el-table-column>
        <el-table-column
            show-overflow-tooltip
            prop=""
            label="在园幼儿数">
          <template slot-scope="scope">
            <div>{{formatNumber(scope.row.studentCount)}}</div>
          </template>
        </el-table-column>
      <el-table-column
          show-overflow-tooltip
          prop=""
          label="积分">
        <template slot-scope="scope">
          <div>{{formatNumber(scope.row.scores)}}</div>
        </template>
      </el-table-column>
      <el-table-column
          :resizable="false"
          show-overflow-tooltip
          prop="address"
          label="操作">
        <template>
          <el-button size="mini" @click="showKindergarten({status:1, province:$store.state.userInfo.province,provinceInfo:$store.state.userInfo.provinceId})" >查看
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    </div>
    <comPagination  :params="pageParams" @changePage="changePage"></comPagination>
  </div>
</template>

<script>
import comPagination from "@/components/admin/pagination";
import supervise from "@/api/supervise";

export default {
  name: "gardenTable",
  components: {
    comPagination
  },
  //计算属性
  computed: {
    rank() {
      return this.$store.state.userInfo.superviseRank
    },
  },
  data() {
    return {
      isCountry:null,
      // 当前选中标签的名字
      activeTabName: 'first',

      // 督导信息列表数据
      gardenList: [],

      // 督导信息统计

      gardenListAll:[],
      listNew: [{}],

      // 督导列表查询参数
      listParam: {
        typeTab: 0, // 信息类型
        year: '', //时间类型: 1昨日、 2上周、 3上月
        province: '', //省级地区名称：当为省级督导时此值为其督导的地区
        city:'',
        type:0,  //全部传 : 空 3: 足特园 4：示范园 5：公益园 7：政采扶持园
      },

      // 督导信息分页参数
      pageParams: {
        page: 1,
        size: 10,
        total: 0
      },

      // 是否显示 督导信息列表数据 还是 园所信息列表数据
      showTable: true,

      // 时间下拉框选项
      dataTypes: [
        {value: '', label: '全部'},
        {value: 2019, label: '2019年'},
        {value: 2020, label: '2020年'},
        {value: 2021, label: '2021年'},
      ],

      // 地区下拉框选项：各个省份
      areas: [],
      areasProvince:[],
      cities:[],

      // 督导列表表头 省
      tableHeaders: {
        // 督导信息表头
        0: [
          {prop: 'province', label: '省',},
          {prop: 'schoolCount', label: '总注册园所'},
          {prop: 'studentCount', label: '总幼儿数'},
          {prop: 'schoolZtCount', label: '足特园所数'},
          {prop: 'schoolSfCount', label: '示范园所数'},
          {prop: 'schoolGyCount', label: '公益园所数'},
          {prop: 'scores', label: '积分'},
        ],

        // 足特信息表头
        1: [
          {prop: 'province', label: '省'},
          {prop: 'schoolCount', label: '总注册园所'},
          {prop: 'schoolZtCount', label: '足特园所数'},
          {prop: 'year', label: '年度'},
          {prop: 'studentCount', label: '在园幼儿数'},
          {prop: 'scores', label: '积分'},
        ],

        // 示范园信息表头
        2: [
          {prop: 'province', label: '省'},
          {prop: 'schoolCount', label: '总注册园所'},
          {prop: 'schoolSfCount', label: '示范园所数量'},
          {prop: 'year', label: '年度'},
          {prop: 'studentCount', label: '在园幼儿数'},
          {prop: 'supportStudentCount', label: '资助幼儿数'},
          {prop: 'scores', label: '积分'},
        ],

        // 公益园信息表头
        3: [
          {prop: 'province', label: '省'},
          {prop: 'schoolCount', label: '总注册园所'},
          {prop: 'schoolGyCount', label: '公益园所数量'},
          {prop: 'studentCount', label: '在园幼儿数'},
          {prop: 'scores', label: '积分'},
        ],
      },
      tableCityHeaders: {
        // 督导信息表头
        0: [
          {prop: 'city', label: '市'},
          {prop: 'schoolCount', label: '总注册园所'},
          {prop: 'studentCount', label: '总幼儿数'},
          {prop: 'schoolZtCount', label: '足特园所数'},
          {prop: 'schoolSfCount', label: '示范园所数'},
          {prop: 'schoolGyCount', label: '公益园所数'},
          {prop: 'scores', label: '积分'},
        ],

        // 足特信息表头
        1: [
          {prop: 'city', label: '市'},
          {prop: 'schoolCount', label: '总注册园所'},
          {prop: 'schoolZtCount', label: '足特园所数'},
          {prop: 'year', label: '年度'},
          {prop: 'studentCount', label: '在园幼儿数'},
          {prop: 'scores', label: '积分'},
        ],

        // 示范园信息表头
        2: [
          {prop: 'city', label: '市'},
          {prop: 'schoolCount', label: '总注册园所'},
          {prop: 'schoolSfCount', label: '示范园所数量'},
          {prop: 'year', label: '年度'},
          {prop: 'studentCount', label: '在园幼儿数'},
          {prop: 'supportStudentCount', label: '资助幼儿数'},
          {prop: 'scores', label: '积分'},
        ],

        // 公益园信息表头
        3: [
          {prop: 'city', label: '市'},
          {prop: 'schoolCount', label: '总注册园所'},
          {prop: 'schoolGyCount', label: '公益园所数量'},
          {prop: 'studentCount', label: '在园幼儿数'},
          {prop: 'scores', label: '积分'},
        ],
      },

      schoolTypes:{
        '0': '公立',
        '1':'私立'
      },
      authStatusTypes:{
        '0': '未认证',
        '1':'已认证'
      },

      currentStates:{
        '0':'未申请',
        '1':'已申请',
        '2':'足特园',
        '3':'未通过',
      },
    }
  },
  methods: {
    gardenListHeader(rank) {
      return rank === 1
          ? this.tableHeaders[this.listParam.typeTab]
          : this.tableCityHeaders[this.listParam.typeTab]
    },
    removeProvince(index) {
      this.$delete(this.listParam.province, index)
    },
    removeCity(index) {
      this.$delete(this.listParam.city, index)
    },

    /**
     * 切换标签回调
     * @param {vm} tab 选中的tab实例：根据其名字找到对应的信息类型：0全部、1足特信息、2示范园信息、3公益园信息
     */
    changeTab(tab) {
      const types = {
        first: 0,
        second: 1,
        third: 2,
        fourth: 3,
      }
      console.log('切换标签', tab, types[tab.name])  //schoolCreditType[tab.name])

      this.listParam.typeTab = types[tab.name] || 0
      this.listParam.type = types[tab.name] || 0
      this.listParam.province = ''
      this.listParam.year =''
      this.getSuperviseProvinceInfosList()  //督导列表
      this.getSuperviseCiytSumInfosAll()  //督导统计
    },

    /*** 翻页回调*/
    changePage(pageNum = 1) {
      this.pageParams.page = pageNum
      this.getSuperviseProvinceInfosList()
    },
   //搜索参数
    getListParam() {
      const data = {
       // dateType: this.listParam.dateType,
        year: this.listParam.year,
        type: this.listParam.type
      }
      const rank = this.$store.state.userInfo.superviseRank
      if(rank === 1 ){
        console.log('省')
        if(this.listParam.province.length) data.provinces = this.listParam.province.join(',')
      }else if(rank === 2){
        console.log('市')
        data.province = this.$store.state.userInfo.province
        if(this.listParam.city.length) data.citys  = this.listParam.city.join(',')
      }
      switch (this.listParam.type ){
        case 0:
          data.type = 0
          break
        case 1:
          data.type = 3
          break
        case 2:
          data.type = 4
          break
        case 3:
          data.type = 5
          break
      }
      return data
    },

    //督导信息列表
    getSuperviseProvinceInfosList(){
      const rank = this.$store.state.userInfo.superviseRank
      let data = this.getListParam()
      console.log('data',data)
      supervise.getSuperviseProvinceInfos(rank,this.pageParams,data).then((res)=>{
        console.log('督导信息分页res',res.data.total)
        if(res.data.total == 0){
         this.gardenList = []
          this.pageParams.total = 1
        }else{
          this.gardenList = res.data.list
          this.pageParams.total = res.data.total
        }
      })
    },

    //督导统计信息
    getSuperviseCiytSumInfosAll(){
      const rank = this.$store.state.userInfo.superviseRank
      let data = this.getListParam()
      supervise.getSuperviseCiytSumInfos(rank,data).then((res)=>{
        if(res.data.count == 0){
          this.gardenListAll = []
        }else{
          this.gardenListAll =[]
          this.gardenListAll.push(res.data)
        }
        console.log('gardenListAll',   this.gardenListAll)
      })
    },
  //查询
    search(){
      this.getSuperviseProvinceInfosList()  //督导列表
      this.getSuperviseCiytSumInfosAll()  //督导统计
    },
  //重置
    reset(){
      console.log('重置参数',this.getListParam)
      this.pageParams.page= '1'
      this.listParam.province=''
      this.listParam.city =''
      this.listParam.year = ''
      this.getSuperviseProvinceInfosList()  //督导列表
      this.getSuperviseCiytSumInfosAll()  //督导统计
    },

    //全国省份列表
    getAreas() {
      return this.api.datav.cascaded().then(res => {
        const rank = this.$store.state.userInfo.superviseRank
        const userProvinceId = this.$store.state.userInfo.province //用户督导的省份ID
        // const userCityId = this.$store.state.userInfo.city // 用户督导的城市ID
        // const userCityId = this.$store.state.userInfo.provinceId //用户督导的省份ID
        // const userProvinceId = this.$store.state.userInfo.cityId // 用户督导的城市ID
        const self = this
        this.areasProvince= []  //省
        this.cities = []  //市
        this.areas = []  //区
        let data = res.data || []

        // 督导级别rank 1全国 2省级 3市级
        switch (rank) {
          case 1:
            self.areas.push({value: '', label: '全国'})
            data.forEach(province => {
              self.areasProvince.push({value: province.label, label: province.label})
            })
            break
          case 2:
            self.areas.push({value: '', label: this.$store.state.userInfo.province})
            data.forEach(province => {
              if (province.label === userProvinceId) {
                self.listParam.province = province.label
                //北京市、天津市、上海市、重庆市
                province.children.forEach(city => {
                    self.cities.push({value: city.label, label: city.label})
                  })
                }
            })
            break
        }
        return Promise.resolve(self.areas)
      })
    },

    //查看
    showKindergarten(row){
      console.log('row',row.province)
      if(row.province == '新疆生产建设兵团'){
        this.$router.push({
          path: "/kindergartenTable",
          query:{
            type: this.listParam.typeTab,
            status: 1,
            province: '新疆生产建设兵团',
            city: row.city,
          }
        })
        return
      }
      this.$router.push({
        path: "/kindergartenTable",
        query: {
          rank: this.$store.state.userInfo.superviseRank,
          type: this.listParam.typeTab,
          province: row.province,
          provinceInfo:row.provinceInfo.id,
          city: row.city,
          status: row.status,
        },
      });

    },
    //导出
    getExpor() {
      const rank = this.$store.state.userInfo.superviseRank
      const data = this.getListParam()
      supervise.getExportSuperviseInfos(rank,data)
          .then(res => {
            return this.api.admin.exportSchoolCallback(res.msg).then(res => {
              // window.location.href = res.message
              window.open(res.message)
              this.$notice({
                type: "success",
                message: "导出成功",
              }).isShow();
            })
          })
    },
    //数字每三位加个逗号
    formatNumber (num) {
      var reg=/\d{1,3}(?=(\d{3})+$)/g;
      return (num + '').replace(reg, '$&,');
    },
  },
  mounted() {
   this.getSuperviseProvinceInfosList()
   this.getSuperviseCiytSumInfosAll()
   this.getAreas()
  }
}
</script>

<style lang="scss" scoped>
/deep/ .el-select{
  position: relative;
}
/deep/.el-select__tags{
  //position: absolute;
  //top: 80px;
  //left: 10px;
}
.provinceAdds{
  overflow: hidden;
   ul{
     margin-top:30px;
     li{
       float: left;
       padding: 5px 10px 5px 15px;
       background: #ED787E;
       border-radius: 12px;
       color: #fff;
       font-size: 14px;
       margin-right: 20px;
       margin-bottom: 10px;
       em{
         display: inline-block;
         width: 10px;
         height: 9px;
         background: url(../../assets/img/Icon_Adr_Close.png) no-repeat;
         background-size: 100% 100%;
         justify-content: center;
         margin-top: 10px;
         cursor: pointer;
       }
     }
   }
}
.exportBtn{
  position: absolute;
  right: 0;
}



.statistical{
  margin-top: 20px;
  width: 100%;
  height: 54px;
  line-height: 54px;
  background: #FFFFFF;
  box-shadow: 0 2px 12px 0 rgba(189, 189, 189, 0.41);
  border-radius: 4px;
  font-size: 16px;
  font-weight: bold;
  color: #333333;
  em{
    display: inline-block;
    font-weight: normal;
    font-size: 16px;
    width: 160px;
    height: 54px;
    padding-left: 30px;
    box-sizing: border-box;
    background: url(../../assets/img/PIc_Bg_All.png) no-repeat;
    background-size: cover;
    color: #fff;
    margin-right:100px;
  }
  span{
    display: inline-block;
    text-align: center;
  }
}
.emAll{
  display: inline-block;
  font-size: 16px;
  width: 160px;
  height: 54px;
  line-height: 54px;
  font-weight: bold;
  box-sizing: border-box;
  background: url(../../assets/img/PIc_Bg_All.png) no-repeat;
  background-size: cover;
  color: #fff;
  margin-right:100px;
  text-align: left;
  padding-left: 30px;
}


/deep/.boxPaddingNone .el-table .el-table__cell{
  padding: 0 !important;
}
/deep/.boxPaddingNone .el-table .cell, .el-table--border .el-table__cell:first-child .cell{
  padding-left: 0 !important;
  font-weight:bold !important;
}


/deep/.el-form-item__label{
  font-size: 18px;
  color: #333333;
}

/deep/ .el-cascader {
  float: left;
}

/deep/ .el-cascader {
  float: left;
  margin-right: 30px;
}

/deep/ .el-tabs__content {
  overflow: inherit;
}

.box-shadow {
  box-shadow: 0 2px 12px 0 rgba(189, 189, 189, 0.41);
}

.searchBox {
  position: relative;
  margin-bottom: 48px;
}

.export {
  width: 112px;
  height: 56px;
  line-height: 56px;
  color: #fff;
  font-size: 18px;
  background: url(../../assets/img/superPic/Btn_Bg_PK.png) no-repeat;
  background-size: 100% 100%;
  text-align: center;
  cursor: pointer;
  border:none;
}

/deep/ .el-button el-button--default el-button--mini {
  color: #fff;
}
.searchBtn {
  float: left;
  width: 50px;
  height: 56px;
  background: url(../../assets/img/superPic/Btn_Bg_Serch.png) no-repeat;
  background-size: 100% 100%;
  text-align: center;
  position: relative;
  top: -7px;
  cursor: pointer;

  img {
    display: inline-block;
    margin: 0 auto;
    width: 27px;
    height: 26px;
    margin-top: 13px;
  }
}

.fl {
  float: left;
}

.gardenName {
  width: 1600px;
  margin: 0 auto;
  padding: 41px;
  box-sizing: border-box;
}

/deep/ .el-tabs__header {
  height: 42px;
  line-height: 42px;
  margin: 40px 0;
  background: #FFFFFF;
  border-radius: 2px;
  font-size: 20px;
  font-weight: 400;
  color: #374D86;
}
/deep/.el-tabs__nav-scroll{
  overflow: inherit;
}

/deep/ .el-tabs__nav{
  box-shadow: 0 2px 12px 0 rgba(189, 189, 189, 0.41);
  padding-left: 10px;
  padding-right: 10px;
  height:42px;
  //position: relative;

}

/deep/ .el-tabs--top .el-tabs__item.is-top:last-child {
  padding-right: 20px !important;
}

/deep/ .el-tabs--top .el-tabs__item.is-top:nth-child(2) {
  padding-left: 20px !important;
}


/deep/.el-tabs__nav-wrap{
  overflow: inherit;
}
/deep/ .el-tabs__nav-wrap::after {

  background: none !important;
}

/deep/ .el-tabs__active-bar {
  width: auto !important;
}

/deep/ .el-tabs__item.is-active {
  height: 48px;
  line-height: 48px;
  background: linear-gradient(232deg, #364B82, #4C66AD);
  border-radius: 6px;
  color: #fff;
  margin-bottom: 4px;
  border-bottom: none;
  position: relative;
  top: -3px;
}

/dee/ .el-date-editor.el-input, .el-date-editor.el-input__inner {
  width: auto !important;
}
/deep/ .el-input--suffix .el-input__inner{
  height: 40px;
}
/deep/ .el-tag.el-tag--info{
  display: inline-block;
}

/deep/ .el-input {

}

/deep/ .el-select {
  float: left;
  margin-right: 30px;
}

/deep/ .el-input, .el-date-editor.el-input__inner {
  width: 240px !important;
  height: 38px !important;
}

//表格样式
/deep/ .el-table th {
  background: #BA3037;
  color: #fff;
  border: none;
  text-align: center;
}

/deep/ .el-table--enable-row-transition .el-table__body td {
  text-align: center;
  border: none;
  border-bottom: 1px solid #DDDDDD;
  color: #333;
  font-size: 14px;
  line-height: 42px;
}

/deep/ .el-table .operation {
  width: 150px;

  .operationList {

  }

}

/deep/ .el-button--mini, .el-button--small {
  border: none;
  color: #BA3037;
  font-size: 14px;
  background: none;
}

/deep/ .el-table--striped .el-table__body tr.el-table__row--striped td {
  background: #fff !important;
}
</style>
